@font-face {
  font-family: 'WIVFont';
  src: local('WIVFont'),
    url('./Fonts/WIVFont.ttf') format('woff2')
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #202020;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6a6a6a;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.container {
  background-repeat: no-repeat;
  overflow: hidden;
  width: var(--container-width-lg);
  margin: 0 auto;
}

.dynamic-container {
  width: 95%;
  height: 100%;
  position: absolute;
  left: 5%;
}

.dynamic-container-right {
  width: 95%;
  height: fit-content;
  position: absolute;
  right: 5%;
}

.dynamic-container-box {
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: "WIVFont";
  font-weight: bold;
  font-size: 50px;
  top: 50%;
  transform: translateY(-50%);
}

.dynamic-container-box-right {
  display: flex;
  flex-direction: column;
  float: right;
  position: relative;
  font-family: "WIVFont";
  font-weight: bold;
  font-size: 50px;
}

.map-container {
  width: 100%;
  height: 100vh;
}


/* unvisited link */
a:link {
  color: white;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: white;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: lightslategray;
  text-decoration: none;
}

/* selected link */
a:active {
  color: white;
  text-decoration: none;
}

.banner1 {

  background: rgb(2, 0, 36);
  background: #1A191B;
  background-size: auto;
  border-bottom: 30px solid #FAC300;
}

.videooverlay {
  position: absolute;
  bottom: 35%;
  left: 35%;
  right: 35%;
  overflow: hidden;
  width: "200%";
  height: "auto";
}

.videoTag {
  z-index: 100;
  height: 100vh;
  object-fit: cover;

}

.overlay-logo {
  width: 100%;
  height: auto;
  filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.5));
}

.headline {
  font-size: 70px;
  font-weight: 600;
  color: white;
}

.wrapper {
  background-color: #1A1A1D;
}

.contentbox {
  font-size: xx-large;
  color: white;
  font-weight: 200;
}

.contentbox-down {
  margin-left: 5%;
  font-size: xx-large;
  color: white;
  margin-top: 100px;
  margin-bottom: 100px;
  font-weight: 600;
}

.headline-small {
  font-size: 40px;
  font-weight: 600;
  color: white;
  margin-left: 20%;
}

.headline-small-center {
  margin-top: 100px;
  font-size: 40px;
  font-weight: 600;
  color: white;
}

.secondrow {
  font-weight: 200;
  font-size: 30px;
  float: left;
  color: white;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: -3%;
}

.spaceinbetween {
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px 0;
  width: 50px;
  height: 50px;
}

.overlay-logo-mobile {
  width: 100%;
  height: auto;
  filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.5));
}

.headline-small-center-mobile {
  margin-top: 100px;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20%;
  margin-left: 5%;
  margin-right: 5%;
  color: white;
}

.contentbox-mobile {
  font-size: 20px;
  color: white;
  font-weight: 200;
  margin-left: 5%;
  margin-right: 5%;
}

.ImageBoxMobile {
  width: 20vw;
  height: auto;
}

.TableBlocks{
  padding: 200%;
}